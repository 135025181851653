import * as React from 'react'
import { PageProps, graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import Layout from '../components/layout/layout'
import Youtube from '../components/layout/youtube'
import Seo from '../components/layout/seo'
import { ImageNodes, YTResources } from '../components/types'

import thumbData from '../images/letoltes/data.json'

type DownloadsProps = {
	allFile: {
        nodes: ImageNodes,
    },
	file: {
		base: string,
		childImageSharp: {
			gatsbyImageData: IGatsbyImageData,
		},
	}
}
const Downloads: React.FC<PageProps<DownloadsProps>> = ({ data: { allFile: { nodes }, file } }) => {

	const videoResource: YTResources = [{
		title: 'NOÉ Állatotthon az ÉLETOtthon - a Te segítségeddel!',
		image: 'Xsf4FhpoXjc.jpg',
		url: 'Xsf4FhpoXjc'
	}]

	const imageDivClass = `w-full md:w-1/2 lg:w-1/3 my-4 md:px-2 lg:px-3 xl:px-4 text-center`
	
	return (
		<Layout>
			<Seo title="Letölthető bannereink és videóink" />
			<div className="my-6 p-8 bg-white">
                <article className="prose md:prose-sm max-w-none
                    prose-h1:text-[#077ab3] prose-h1:uppercase prose-h1:font-bold prose-h2:text-[#87c2e1]
                    prose-h3:text-base md:prose-h3:text-base prose-p:text-justify
                    prose-a:text-[#0088cc] prose-a:font-bold prose-a:underline-offset-2 hover:prose-a:text-[#005580]">
					<h1>Letölthető bannereink és videóink</h1>
					<div className="my-4 not-prose">
						<Youtube resources={ videoResource } nodes={ [file] } />
					</div>
					{ /* <div className="flex flex-wrap justify-center my-4 md:-mx-2 lg:-mx-3 xl:-mx-4 not-prose">
					{
						thumbData.resources.map((resource, index) => {
							const image = nodes.filter(({ base }) => base == resource.image)[0]
								return (
									<div key={ index }
										className={ index < 12 && index > 8 ? imageDivClass + ` order-2 lg:order-none` : imageDivClass }>
										<a className="block border-2 border-transparent hover:border-blue-300"
											key={ index } target="_blank" rel="noreferrer"
											href={ '/letoltes/' + resource.image }
											aria-label={ 'Teljes méretű kép: ' + resource.title }
										>
											<GatsbyImage
												image={ image.childImageSharp.gatsbyImageData }
												alt={ resource.title } />
										</a>
										<a className="inline-block w-auto my-2 py-1.5 px-4
												rounded-full bg-[#005580] hover:bg-[#0088cc] text-white font-bold"
											aria-label={ 'Banner letöltése: ' + resource.title }
											href={ resource.download ? '/letoltes/' + resource.download : '/letoltes/' + resource.image }
											download={ resource.download ? resource.download : resource.image }
										>
											A banner letöltése
										</a>
									</div>
								)
						})
					}
					</div>
					<div className="my-4 text-center not-prose">
						<a className="inline-block w-auto my-2 py-2 px-4
								rounded-full bg-[#005580] hover:bg-[#0088cc] text-white font-bold"
							href={ '/letoltes/noe_osszes_banner.zip' }
							download="noe_osszes_banner.zip"
						>
							A bannerek letöltése egyben
						</a>
					</div> */ }
				</article>
			</div>
		</Layout>
)}

export default Downloads

export const query = graphql`
    {
        allFile(
            filter: {
                relativeDirectory: { eq: "letoltes" },
                extension: { regex: "/(jpg)|(png)|(jpeg)/" } }
        ) {
            nodes {
				base
				childImageSharp {
					gatsbyImageData(
						placeholder: TRACED_SVG
						layout: FULL_WIDTH
						breakpoints: [290, 370, 572, 960]
					)
				}
            }
        },
		file(relativePath: { eq: "video/Xsf4FhpoXjc.jpg" }) {
			base
			childImageSharp {
				gatsbyImageData(
					placeholder: TRACED_SVG
					layout: FULL_WIDTH
					breakpoints: [640, 768, 1024, 1280]
				)
			}
		}
    }
`